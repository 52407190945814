<template>
  <div>
    <router-link :to="{ name: 'admin.details.create' }" class="btn btn-theme btn-sm float-end"><i class="lni lni-plus me-2"></i>Add New</router-link>
    <h3>Property Details Options</h3>
    <loading v-if="loading" />
    <div class="card mt-3" v-if="! loading">
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th colspan="2">Type</th>
              <th colspan="2"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="details.length == 0">
              <td colspan="6">There is no data to display</td>
            </tr>
            <tr v-for="(detail, i) in details" :key="`detail-${i}`">
              <td>{{ i+1 }}</td>
              <td>{{ detail.key }}</td>
              <td>{{ detail.type }}</td>
              <td>
                <ul>
                  <li v-for="(option, j) in detail.options" :key="`detail-option-${(i+1)*j}`">{{ option }}</li>
                </ul>
              </td>
              <td width="10%" align="right">
                <router-link :to="{  name: 'admin.details.edit', params: { id: detail.id }}" class="text-primary"><i class="lni lni-pencil me-2"></i></router-link>
              </td>
              <td width="10%" align="right">
                <a href="#" @click.prevent="destroy(detail)" class="text-danger"><i class="lni lni-trash-can me-2"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      details: [],
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.axios.get('/api/v1/admin/details').then(response => {
        this.details = response.data.details
        this.loading = false
      })
    },

    destroy(detail) {
      this.loading = true

      this.axios.delete(`/api/v1/admin/details/${detail.id}`).then(() => {
        this.loading = false
        this.fetch()
        this.$swal({
          title: "Deleted",
          text: "The item has been deleted",
          type: 'success'
        })
      })
    }
  }
}
</script>